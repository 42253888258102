/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #56595e;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #60791f;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.header {
  width: 100vw;
  height: 60px;
  background-color: #000;
  color: #FFF;
  position: fixed;
  z-index: 1000;
  :focus {
    outline: none;
  }
}

.header-menu {
  background-color: #FFF;
  width: 50vw;
}

.header-row {
  padding-top: 20px;
}

.header-row a {
  padding: 5px;
}

.invert{
    -webkit-filter: invert(100%);
}

.link-col {
  padding: 5px;
}

.menu-col-left {
  padding-top: 5vh;
}

.menu-col-right {
  text-align: left;
  padding-top: 15vh;
  font-size: 40px;
}

.menu-col-right a {
  color: #b8b7ad;
}

@media screen and (max-width: 1000px) {
  .header-row {
    display: none;
  }

  .menu-col-right {
    font-size: 20px;
  }
}
