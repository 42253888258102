.about {
}

.about-bg {
    background-image: url('../../helpers/images/about.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    height: 100vh;
}

.about-content {
    font-size: 20px;
    min-height: 100vh;
    max-width: 100vw;
    padding: 5% 22% 5% 22%;
    text-align: center;
}

.about-title {
    color: #3349FF;
    font-size: 20vw;
    height: 100vh;
    padding-top: 30vh;
}

@media screen and (max-width: 1000px) {
    .about-content {
        font-size: 12px;
        padding: 5% 2% 5% 2%;
    }
}