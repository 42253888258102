.footer {
    background-color: #000;
    color: #FFF;
    padding: 5% 0 5% 0;
    width: 100vw;
    height: 20%;
}

.footer a {
  padding: 20px;
}

.footer-address {
  // text-align: left;
}
