.start {
}

.start-bg {
    height: 100vh;
    width: 100vw;
    background-image: url('../../helpers/images/bg-start.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.start-title {
    color: #3349FF;
    font-size: 20vw;
    height: 100vh;
    padding-top: 30vh;
}

.start-calendar {
    display: inline-block;
}

.start-content {
    min-height: 100vh;
    max-width: 100vw;
    padding-bottom: 20vh;
    text-align: center;
}

.start-list {
    display: inline-block;
    padding: 0 10vw 0 10vw;
    text-align: left;
}